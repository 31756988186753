// Entry point for the build script in your package.json

import $ from "jquery";

$(function () {
  $(".nav-toggle").click(function (e: JQuery.Event) {
    e.preventDefault();
    $("body").toggleClass("show-nav");
  });

  const navBg = $("nav > .image").get(0);

  $(".nav-link").hover(function () {
    const url = $(this).data("bg") as string;

    if (url) {
      navBg.style.backgroundImage = "url(" + url + ")";
    } else {
      navBg.style.backgroundImage = "none";
    }
  });
});
